import axios from 'axios'
const config = {
    headers: {
      // 'Accept':'application/json'
      'Access-Control-Allow-Origin': '*'
    }
  }
const url = 'https://www.meliuz.com.br/pixel/pa/7074.png?auth=5ecc41240a68d'
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*'

export const setMeliuz = (id_transaction, value, user_id) => { 
  const valor = value.toFixed(2)
  const data = url + '&xtra=' + id_transaction + '&iso_currency=BRL&commission=50.00&value='+valor+'&order='+user_id+'&​cart=1%7Cenercred%3B1%3B' + valor
  // console.log(data)
  return axios.get(data, config)
}
<template>
  <div style="background-color: #FFF; min-height: 100vh;">
    <!-- <a id="logo" href="https://alvarotrigo.com/fullPage/" alt="fullPage homepage">Enercred</a> -->
    <v-layout align-center justify-center column>
      <v-flex xs12 sm12 md12 pt-5 pb-5 v-if="!this.uc.id || hasSigned">
        <!-- <a href="https://enercred.com.br" target="_blank" alt="Enercred - Energia Limpa e Barata"> -->
          <img
            src="@/assets/logo.svg"
            width="180px"
            style="padding: 0px 0"
            alt="Enercred - Energia Limpa e Barata"
          />
        <!-- </a> -->
      </v-flex>
      <v-flex
        v-if="!loading_inicio && !user.customer_id"
        xs12
        sm12
        md12
        class="align-center justify-center row fill-height pr-3 pl-3 "
      >
        <div style="font-size: 22px; font-weight: 800">Usuário não identificado!</div>
        <p>
          Por favor, entre em contato entre em contato com nosso time pelo telefone
          <a
            href="tel:+553135003614"
            target="_blank"
          >(31) 3500-3614</a>
          , pelo whatsapp
          <a
            href="https://wa.me/553536291633"
            target="_blank"
          >(35) 3629-1633</a>
          ou email
          <a
            href="mailto:contato@enercred.com.br?subject=Por que devo escolher a forma de Pagamento"
            target="_blank"
          >contato@enercred.com.br.</a>
        </p>
      </v-flex>
      <v-flex
        xs12
        sm12
        md12
        v-if="!loading_inicio && hasSigned"
        class="align-center pr-3 pl-3 justify-center row fill-height"
      >
        <div class="title" style="font-size: 1.4rem; font-weight: 800">{{firstName}}</div>
        <p
          class="subheader"
        >Verificamos que você já assinou o contrato em {{data_assinatura}}</p>
      </v-flex>
    </v-layout>
    <div class="text-xs-center" v-if="loading_inicio">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <div id="app" v-if="!loading_inicio && user.customer_id && !hasSigned">
      <!-- <ul id="menu" style="background-color:#000"></ul> -->
      <v-container fluid grid-list-md style="">
        <v-layout row wrap>
          <!-- logo  mobile-->
          <v-flex v-if="loading_inicio" align-center justify-center row>
            <v-progress-circular :size="40" :width="4" color="success" indeterminate></v-progress-circular>
          </v-flex>
          <div class="hidden-md-and-up" style="padding: 3%;">
            <a
              href="https://enercred.com.br"
              target="_blank"
              alt="Enercred - Energia Limpa e Barata"
            >
              <img
                src="../../assets/logo.svg"
                width="150px"
                style="padding: 5px"
                alt="Enercred - Energia Limpa e Barata"
                class="src"
              />
            </a>
            <!-- Detalhe da assinatura para Mobile -->
            <v-flex xs12 sm6>
              <h4 cla>Proposta de assinatura de energia limpa</h4>
            </v-flex>
            <v-layout row>
              <v-flex xs12 sm6>
                <v-list three-line>
                  <v-list-tile ripple>
                    <v-list-tile-action>
                      <v-icon color="#A4C425">star</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-sub-title>Economize até <strong>R$ {{economia_anual | formatCurrency}}</strong> por ano.</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile ripple>
                    <v-list-tile-action>
                      <v-icon color="#A4C425">star</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-sub-title>Só começa a pagar <strong>após receber os créditos</strong>.</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile ripple>
                    <v-list-tile-action>
                      <v-icon color="#A4C425">star</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-sub-title>Os <strong>créditos</strong> chegam diretamente em sua conta de energia.</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile ripple>
                    <v-list-tile-action>
                      <v-icon color="#A4C425">star</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-sub-title><strong>Sem</strong> obras.</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile ripple>
                    <v-list-tile-action>
                      <v-icon color="#A4C425">star</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-sub-title>Fidelidade de <strong>90 dias</strong>.</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-flex>
            </v-layout>
            <v-flex xs12 sm12 md6 class="hidden-md-and-up pt-3">
              <!-- <div class="pb-5 text-primary text--darken-3 text--large text-uppercase">Veja quanto você economiza alugando uma fração de nossas usinas solares</div> -->
              <!-- <div class="pb-2 text-primary text--darken-1">Proposta de assinatura de energia limpa</div> -->
              <cpnOrderProposal :uc="uc" />
            </v-flex>
          </div>
          <v-layout row wrap align-center justify-center fill-height style="height: 100vh; background: #efefef">
            <!-- <cpnSteps :steps="{steps: 4, e1: 2}"/> -->
            <!-- Formulario de registro -->
            <v-flex d-flex xs12 sm12 md6 style="text-align: left">
              <div class="form_conta">
                <div class style="padding: 0">
                  <!-- <cpnSteps  class="hidden-sm-and-down" :steps="{steps: 4, e1: 2}"/> -->
                  <!-- <div
                    class="headline hidden-sm-and-up"
                    style="padding-bottom: 15px; color: #808080"
                  >PROPOSTA</div> -->
                  <div
                    class="display-1 hidden-sm-and-down"
                    style="padding-bottom: 15px; color: #808080"
                  >PROPOSTA</div>
                  <!-- <div class="red--text pb-4">Os dados a seguir devem ser do titular da conta de energia.</div> -->
                  <v-form ref="form" v-model="valid"  class="form_class">
                    <!-- Nome -->
                    <v-flex xs12>
                      <strong>{{user.name}}</strong>
                    </v-flex>
                    <!-- CPF -->
                    <v-flex xs12>
                      CPF: <strong>{{cpf}}</strong>
                    </v-flex>
                    <!-- Unidade Consumidora -->
                    <v-flex xs12>
                      No. Instalação: <strong>{{uc.installation_number}}</strong>
                    </v-flex>
                    <v-flex xs12>
                      No. Cliente Cemig: <strong>{{uc.customer_number}}</strong>
                    </v-flex>
                    <v-flex xs12>
                      Créditos previstos para: <strong>Junho/2020</strong>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 pt-3 pb-4>
                      <p style="color: green">Preencha os dados abaixo para garantir sua vaga na usina e começar a economizar</p>
                    </v-flex>

                    <!-- Senha -->
                    <!-- <v-flex xs12>
                      <v-text-field
                        v-model="senha"
                        type="password"
                        label="Senha"
                        name="senha"
                        :rules="[
                          v => !!v || 'Por favor, informe uma senha!',
                          v => 
                            v && v.length >= 8 ||
                            'Sua senha deve possuir 8 caracteres ou mais!'
                        ]"
                        required
                      ></v-text-field>
                    </v-flex> -->

                    <!-- Confirmar Senha -->
                    <!-- <v-flex xs12>
                      <v-text-field
                        type="password"
                        label="Confirmar Senha"
                        name="confirmar_senha"
                        :rules="[
                          v => !!v || 'Por favor, confirme sua senha!',
                          v =>
                            v && v.length >= 8 ||
                            'Sua senha deve possuir 8 caracteres ou mais!',
                            v => v === this.senha || 'Senhas não combinam',

                        ]"
                        required
                      ></v-text-field>
                    </v-flex> -->
                    <!-- Forma de Pagamento -->
                    <header style="text-align: left">Forma de Pagamento</header>
                    <v-radio-group
                      v-model="payment_type"
                      :rules="[
                        v => !!v || 'Por favor, informe como deseja pagar sua energia limpa!'
                      ]"
                    >
                      <v-radio :key="1" :label="`Boleto`" value="boleto"></v-radio>
                      <v-radio :key="2" :label="`Cartão de Crédito`" value="credit_card"></v-radio>
                    </v-radio-group>
                    <!-- Profissao -->
                    <v-flex xs12>
                      <v-text-field
                        v-model="profissao"
                        label="Profissão do titular"
                        name="profissao"
                        placeholder="Ex. Aposentado"
                        :rules="profissaoRules"
                        required
                      ></v-text-field>
                    </v-flex>
                    <!-- identidade -->
                    <v-flex xs12 v-if="!isIdentidade">
                      <v-text-field
                        v-model="identidade"
                        label="Identidade do titular da conta de energia"
                        name="identidade"
                        :rules="identidadeRules"
                        required
                      ></v-text-field>
                    </v-flex>
                    <!-- data de nascimento -->
                    <v-flex xs12 v-if="!isDate_of_birth">
                      <v-text-field
                        v-model="date_of_birth"
                        label="Data de nascimento do titular da conta de energia"
                        mask="##/##/####"
                        name="date_of_birth"
                        :rules="[v => !!v || 'Informe a data de nascimento']"
                        required
                      ></v-text-field>
                    </v-flex>
                    <!-- genero -->
                    <v-flex xs12 v-if="!isGender">
                      <header style="padding-bottom: 4px;  text-align: left">Sexo do titular da conta?</header>

                      <v-radio-group
                        v-model="gender"
                        :rules="[v => !!v || 'Informe o sexo do titular da conta']"
                        row
                      >
                        <v-radio style="margin-right: 8px" label="Masculino" value="M"></v-radio>
                        <v-radio label="Feminino" value="F"></v-radio>
                      </v-radio-group>
                    </v-flex>
                    <!-- Estado civil -->
                    <v-flex xs12>
                      <header
                        style="padding-bottom: 4px; padding-left: 3px; text-align: left"
                      >Estado civil do titular da conta?</header>
                      <v-radio-group
                        v-model="estadoCivil"
                        row
                        @click="deselecionaRegimeDeBens()"
                        :rules="[v => !!v || 'Por favor, informe o estado civil do titular da conta!']"
                      >
                        <v-radio
                          v-for="(ec, index) in estadosCivis"
                          style="margin-right: 3px"
                          :key="index"
                          :label="ec"
                          :value="ec"
                        ></v-radio>
                      </v-radio-group>
                    </v-flex>
                    <!-- Comunhao de bens -->
                    <v-flex xs12>
                      <header
                        v-if="estadoCivil === 'Casado(a)'"
                        style="text-align: left; padding-bottom: 4px; padding-left: 3px"
                      >Qual Regime de Bens é casado(a). Comunhão:</header>
                      <v-radio-group v-if="estadoCivil === 'Casado(a)'" v-model="comunhaoDeBens">
                        <v-radio
                          v-for="(rdb, index) in regimeDeBens"
                          style="padding-right: 4px"
                          :key="index"
                          :label="rdb"
                          :value="rdb"
                        ></v-radio>
                      </v-radio-group>
                    </v-flex>
                    <!-- Termo de Compromisso -->
                    <v-flex xs12 style="text-align: left; padding: 0 0 0 7px; margin: 0">
                      <v-btn @click="dialog = true" color="primary">Ler Contrato</v-btn>
                    </v-flex>
                    <v-flex xs12>
                      <v-checkbox
                        v-model="termo"
                        @click="dialog = true;"
                        label="Aceito o Contrato de Aluguel"
                        :rules="[
                          v =>
                            !!v ||
                            'É necessário aceitar o contrato de aluguel para concluir o cadastro!'
                        ]"
                      ></v-checkbox>
                    </v-flex>
                    <!-- Botao assinar -->
                    <div class="my-2">
                      <v-btn
                        :loading="loading"
                        :disabled="!valid || loading"
                        block
                        @click.prevent="validate()"
                        large
                        style="background-color: rgb(9, 95, 150)"
                      >Alugar e Economizar</v-btn>
                      <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1928329&conversionId=1958089&fmt=gif" />
                    </div>
                  </v-form>
                  <!-- <v-progress-circular v-if="loading" :size="50" color="teal" indeterminate></v-progress-circular> -->

                  <br />
                </div>
              </div>
            </v-flex>
            <!-- Enercred info -->
            <v-flex xs12 sm12 md6 style="text-align: center;">
              <div class="step2_esquerda" style="padding-top: 0">
                <div class="hidden-sm-and-down">
                  <a
                    href="https://enercred.com.br"
                    target="_blank"
                    alt="Enercred - Energia Limpa e Barata"
                  >
                    <img
                      src="../../assets/logo.svg"
                      width="180px"
                      style="padding: 5px"
                      alt="Enercred - Energia Limpa e Barata"
                      class="src"
                    />
                  </a>
                </div>
                <div>
                  <div class="hidden-sm-and-down pt-3 text-primary text--darken-5 text--large text-uppercase">Veja quanto você economiza alugando uma fração de nossas usinas solares</div>
                  <cpnOrderProposal :uc="uc" class="hidden-sm-and-down pt-5" />
                </div>
                <div class="hidden-sm-and-down pt-5"></div>
              </div>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </div>
    <div class="text-xs-center">
      <v-dialog v-model="dialog" width="500" scrollable>
        <v-card>
          <v-card-title class="headline grey lighten-2 pa-3" primary-title>Contrato de Aluguel</v-card-title>

          <v-card-text class="pa-3">
            <cpnContratoAluguel :uc="uc" />
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="ml-auto">
            <v-spacer></v-spacer>
            <v-btn block color="secondary" @click="acceptUseOfTerms">ALUGAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex"
// import { enviarEmail } from "../../services/sendmail"
import { saveAnalytics } from "@/services/save_analytics"
import { sendClooset } from "../../services/send_clooset"
import { setMeliuz } from "../../services/set_meliuz"
// import { setRDConversion } from "@/services/rdstation";
import { // STORE_LEAD, 
  GET_LEAD } from "../../graphql/leads"
// import { STORE_USER } from "../../graphql/users"
import { GET_PEOPLE } from "../../graphql/people"
import OrderProposal from "../../components/order/OrderProposal"
// import TermsOfUse from "../../components/termsOfUse/TermsOfUse"
import ContratoAluguel from "../../components/termsOfUse/ContratoAluguel"
import { SET_CONVERSION } from "../../graphql/rdstation"
// import Steps from '../../components/core/Steps'
import { getIP } from '../../services/getIP'
import { getUser, updateUser } from '../../services/firebase/user'
import { getLead, updateLead } from '../../services/firebase/lead'
import { getUC, updateUC } from '../../services/firebase/consumer_unit'

import moment from 'moment'

export default {
  components: {
    cpnOrderProposal: OrderProposal,
    // cpnTermsOfUse: TermsOfUse,
    cpnContratoAluguel: ContratoAluguel
    // cpnSteps: Steps
  },
  data() {
    return {
      loading_inicio: true,
      dialog: false,
      loading: false,
      cpf: "",
      titular: "",
      senha: "",
      payment_type: "",
      termo: false,
      valid: false,
      isProd: false,
      estadosCivis: ["Solteiro(a)", "Casado(a)", "Separado(a)", "Divorciado(a)", "Viúvo(a)"],
      estadoCivil: "",
      profissao: "",
      profissaoRules: [value => !!value || "Por favor, informe a profissão!"],
      identidade: "",
      identidadeRules: [value => !!value || "Por favor, informe o numero de identidade!"],
      date_of_birth: "",
      gender: "",
      regimeDeBens: ["Parcial de bens", "Universal de bens", "Separação total", "Final nos aquestos"],
      comunhaoDeBens: "",
      alertMessage: "",
      isIdentidade: false,
      isGender: false,
      isDate_of_birth: false,
      user: {},
      lead: {},
      uc: {},
      ipinfo: {},
      hasSigned: false
    }
  },
  // computed: {
  //   user() {
  //     return this.$store.getters["getUser"]
  //   },
  //   ...mapGetters(["getUser"])
  // },
  created() {
    this.loading_inicio = true
    this.isProd = process.env.NODE_ENV === "production"
    if (this.$route.query.uc) {
      getUC(this.$route.query.uc)
        .then(uc => {
          if (uc.data()) {
            // console.log('uc:', uc.data())
            const user_id = uc.data().user_id
            if (this.isProd) {
              const analytics = {
                eventCategory: "onboard",
                eventAction: "VIEWED_PROPOSAL",
                eventLabel: "VIEWED_PROPOSAL",
                value: user_id
              }
              saveAnalytics(analytics)
            }
            getUser(user_id)
              .then(async user => {
                if (user.exists) {
                  uc = {
                    id: uc.id,
                    ...uc.data(),
                    user: user.data()
                  }
                  // console.log('uc user:',  uc)
                  this.lead = await getLead(user.data().email)
                  this.setUC(uc)
                  if(user.data().signedcontract_at) {
                    this.hasSigned = true
                  }
                  this.loading_inicio = false
                  // this.user = user
                } else {
                  this.loading_inicio = false
                }
              })
              .catch(error => {
                console.error('Erro ao pegar o usuário. erro:', error)
                this.loading_inicio = false
              })
          } else {
            console.error('Unidade Consumidora não encontrada!', this.$route.query.uc)
            this.loading_inicio = false
          }
        })
        .catch(error => {
          console.error('Erro ao pegar o usuário. erro:', error)
          this.loading_inicio = false
        })
    } else {
      console.error('Unidade Consumidora não encontrada!', this.$route.query.uc)
      this.loading_inicio = false
    }
  },
  methods: {
    async getLead(email) {
      return await this.$apollo.query({
        query: GET_LEAD,
        variables: {
          email: email
        }
      })
    },
    setUC(uc) {
      this.uc = uc
      this.user = uc.user
      this.cpf = uc.user.cpf
      this.titular = uc.user.titular
      this.password = uc.user.password
      this.termouso = uc.user.termouso
      this.gender = uc.user.gender
      this.date_of_birth = uc.user.date_of_birth
      this.ID_document = uc.user.ID_document
      this.payment_type = uc.user.payment_type
      this.profissao = uc.user.profession
      this.identidade = uc.user_id.ID_document
      this.isIdentidade = uc.user.ID_document ? true : false
      this.isGender = uc.user.gender ? true : false
      this.isDate_of_birth = uc.user.date_of_birth ? true : false
      this.estadoCivil = uc.user.marital_status,
      this.comunhaoDeBens = uc.user.marital_type
    },
    async validate() {

      if (this.$refs.form.validate() && this.user && this.user.name && this.user.email) {
        this.loading = true
        const ipinfo = await getIP()
        // console.log('ip: ', ipinfo)

        const new_lead = {
          signedcontract_at: new Date(),
          signedcontract_ipinfo: ipinfo.data,
          termouso: this.termo?'Sim':'Não'
        }
        this.storeLead(this.user.email, new_lead)
          .then(result => {
            // console.log('lead: result: ', result.data())
            if (result.exists) {
              localStorage.setItem("lead", JSON.stringify(result.data()))
            }
          })
          .catch(err => {
            this.loading = false
            console.error("Error: ", err)
          })
        
        const user = {
          // ...this.user,
          // termouso: this.termo,
          termouso: this.termo?'Sim':'Não',
          gender: this.gender,
          date_of_birth: this.date_of_birth,
          ID_document: this.ID_document,
          payment_type: this.payment_type || "credit_card",
          status: "ATIVO",
          profession: this.profissao,
          signedcontract_ipinfo: ipinfo.data,
          signedcontract_at: new Date(),
          marital_status: this.estadoCivil,
          marital_type: this.comunhaoDeBens || 'N/A',
          signedcontract_by: 'onboarding',
          signedcontract_source: 'online',
          signedcontract_partner: 'enercred',
        }
        if(this.payment_type === 'boleto') {
          user.boleto_accepted_by =  "onboarding"
          user.boleto_accepted_at = new Date()
          user.boleto_ipinfo = ipinfo.data
        }

        this.$store.commit("storeUserState", user)

        this.storeUser(this.user.customer_id, user)
        // storeUser(user)
          .then((user_ref) => {
            updateUC(this.uc.id, {status: 'activated'})
              .then(() => {
                console.log('Unidade consumidora ' + this.uc.id + ' ativada!')
              })
              .catch(err => console.error('Erro ao ativar a unidade consumidora. ', err))
            
            localStorage.setItem("newUser", JSON.stringify(user_ref.data()))
            // analytics
            if (this.isProd) {
              const analytics = {
                eventCategory: "onboard",
                eventAction: "SIGN-CONTRACT",
                eventLabel: "ACCEPTED_PROPOSAL",
                transaction_id: user.email,
                value: user.average_consumption,
                utm_source: this.user.utm_source,
                utm_medium: this.user.utm_medium,
                utm_campaign: this.user.utm_campaign,
                payment_type: this.user.payment_type,
                boleto: true
              }
              saveAnalytics(analytics)
              // const conversion = {
              //   event_type: "OPPORTUNITY",
              //   event_family: "CDP",
              //   payload: {
              //     email: user.email,
              //     funnel_name: "default"
              //   }
              // }
              const conversion = {
                event_type: "ORDER_PLACED",
                event_family: "CDP",
                payload: {
                  name: this.user.name,
                  email: this.user.email,
                  cf_order_id: this.user.cpf,
                  cf_order_total_items: 1,
                  cf_order_status: "ACCEPTED_PROPOSAL",
                  cf_order_payment_method: this.payment_type==='boleto'?"Boleto":"Credit Card",
                  cf_order_payment_amount: parseInt(user.average_consumption || 0)
                }
              }
              this.setRDConversion(conversion)
              
              /* Envio dado para clooset */
              const closset = {
                id: user.id,
                // order_value: Number(user.average_consumption)
                order_value: 50
              }
              sendClooset(closset)
              if(this.lead.data().utm_source === 'meliuz' && this.lead.data().utm_transaction) {
                setMeliuz(this.lead.data().utm_transaction, parseFloat(this.lead.data().average_consumption), user_ref.data().customer_id)
              }
            }
            if(this.payment_type === 'boleto') {
                this.$router.push("/cadastrar/concluido")
              } else {
                this.$router.push("/cadastro/cartao?email=" + this.user.email)
              }
            this.loading = false
            return true
          })
          .catch(error => {
            console.error("Erro ao criar usuário: ", error)
            this.loading = false
            return false
          })
      } else {
        alert("Desculpe, houve algum erro ao salvar, reiniciaremos o seu cadastro!")
        this.$router.push("cadastro")
      }
    },
    async getPeople(cpf) {
      return await this.$apollo.query({
        query: GET_PEOPLE,
        variables: {
          doc: cpf
        }
      })
    },
    acceptUseOfTerms() {
      this.termo = true
      this.dialog = false
    },
    storeUser(id, data) {
      data.group = "customer"
      // console.log('user: ', data)
      return updateUser(id, data)
    },
    storeLead(email, lead) {
      return updateLead(email, lead)
    },
    validaCPF(strCPF) {
      if (!strCPF) return ""
      var Soma
      var Resto
      Soma = 0
      if (strCPF == "00000000000") return false

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      Resto = (Soma * 10) % 11

      if (Resto == 10 || Resto == 11) Resto = 0
      if (Resto != parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      Resto = (Soma * 10) % 11

      if (Resto == 10 || Resto == 11) Resto = 0
      if (Resto != parseInt(strCPF.substring(10, 11))) return false
      return true
    },
    setRDConversion(conversion) {
      return this.$apollo.mutate({
        // Query
        mutation: SET_CONVERSION,
        // Parameters
        variables: {
          event_type: conversion.event_type,
          event_family: conversion.event_family,
          payload: conversion.payload
        }
      })
    },
    // Quando seleciona estado civil reseta comunhao de bens
    deselecionaRegimeDeBens() {
      this.comunhaoDeBens = undefined
      this.alertMessage = ""
    },
  },
  computed: {
    economia_anual() {
      return this.uc.economia_anual
    },
    data_assinatura() {
      if(!this.user && !this.user.signedcontract_at) return ''
      else if (this.user && this.user.signedcontract_at) {
        // console.log('data assinatura: ', this.user.signedcontract_at)
        // console.log('data assinatura tipo: ', typeof(this.user.signedcontract_at))
        if(typeof(this.user.signedcontract_at) === 'object') {
          const newDate = this.user.signedcontract_at.toDate()
          return moment(newDate).format('DD/MM/YYYY')
        } else {
          const ano = this.user.signedcontract_at.split('-')[0]
          const mes = this.user.signedcontract_at.split('-')[1]
          const dia = this.user.signedcontract_at.split('-')[2].substring(0,2)
          return dia + '/' + mes + '/' + ano
        }
      } else {
        return ''
      }
    },
    firstName() {
      if(!this.user && !this.user.name) return ''
      return this.user.name.split(' ')[0]
    }
  },
  filters: {
    formatCurrency(value) {
      if(!value) return '0,00'
      if(!Number(value)) return '0,00'
      return Number(value).toFixed(2).replace('.',',')
    }
  }

}
// eslint-disable-next-line no-unused-vars
// var $_Huggy = { defaultCountry: '+55', widget_id: '21542', company: '314586' };(function(i,s,o,g,r,a,m){ i[r]={context:{id:'8d38fff17a0b0995d3f91847e60eba7b'}};a=o;o=s.createElement(o); o.async=1;o.src=g;m=s.getElementsByTagName(a)[0];m.parentNode.insertBefore(o,m); })(window,document,'script','https://js.huggy.chat/widget.min.js','pwz');
</script>
<style scoped>
@charset "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  height: 100%;
  background-color: #095f96;
}
template {
  background-color: #095f96;
}
.area {
  height: 100px;
  width: 100px;
  background: red;
  margin: 10px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.content {
  height: 100px;
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.header {
  font-size: 2em;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: #fff;
}
.header span {
  font-size: 2em;
  color: #fff;
  font-weight: bold;
}
.header br {
  font-size: 4em;
  color: #fff;
  font-weight: bold;
}
.form_conta {
  height: 100vh;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  background-color: #fff;
  padding: 1% 5% 10% 5%;
  text-align: left;
  /* border-radius: 10px; */
}
.step2_esquerda {
  width: 95%;
  padding-left: 5%;
  padding-top: 10%;
  text-align: left;
}
/*
.form_class {
  padding-top: 100px;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  text-align: center;
}
*/
.section {
  position: relative;
  text-align: center;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 900;
}
#section-1 h1 {
  font-size: 2em;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-1 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-1-button {
  padding: 0.93em 1.87em;
  background: #00a8bb;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 500;
}
#section-3 h1 {
  font-size: 2em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-3 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-3-button {
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-3 .intro {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 50%;
  padding: 0 3em;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.atencao {
  font-size: 1.5em;
  color: #00a8bb;
  font-weight: bold;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}

/* Actions buttons
 * --------------------------------------- */
.actions {
  position: fixed;
  bottom: 2%;
  margin: 0 auto;
  z-index: 99;
  left: 0;
  right: 0;
  text-align: center;
}
.actions li {
  display: inline-block;
  margin: 0.3em 0.3em;
}
.actions-button {
  padding: 0.73em 1.47em;
  background: rgba(53, 73, 94, 0.47);
  border-radius: 5px;
  display: block;
  color: #fff;
  cursor: pointer;
}

/* Twitter
 * --------------------------------------- */
.twitter-share i {
  vertical-align: middle;
  position: relative;
  top: 2px;
  display: inline-block;
  width: 38px;
  height: 14px;
  color: white;
  top: -4px;
  left: -2px;
  fill: #fff;
}
.twitter-share svg {
  height: 40px;
  margin-top: -10px;
}
.view-github {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}

.shell {
  width: calc(50% - 0px);
  display: inline-block;
  background-color: #fff;
  /*  background-image: url('../assets/eolica.png'); */
  background-position: center;
  border-radius: 0px 0px 0 0;
  text-align: left;
  margin-right: 0px;
}

strong {
  font-size: 1em;
  color: #A4C425;
  font-weight: 800;
}
</style>

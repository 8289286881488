import { firestore } from "../../firebase/firestore"
// import { firebaseAuth } from '../../firebase/firebaseAuth'

// const currentDateTime = new Date()

export const getUC = (id) => {
    return firestore.collection('consumer_units').doc(id).get()
};

export const updateUC = (id, data) => {
    data.updated_at = new Date()
    const ref = firestore.doc(`consumer_units/${id}`)
    return ref.update(data)
        .then(() => {
            return ref.get()
        })
        .catch(error => {
            console.error('Erro ao atualizar usuário')
            return error
        })
}
